import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiUsers,
  FiLogOut,
  FiMenu,
  FiX,
} from 'react-icons/fi';
import { VscOrganization } from 'react-icons/vsc';
import { MdAttachMoney } from 'react-icons/md';
import { FaMoneyCheck } from 'react-icons/fa';

import { useAuth } from '../../hooks/Auth';

import { Container } from './styles';

import Logo from '../../assets/logo.png';

const Menu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const { signOut, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  const handleMenu = useCallback(() => {
    setMenuOpen(!menuOpen)
  }, [menuOpen]);

  return (
    <Container>
      <div className="fixed">
        <div className="logo">
          <img src={Logo} alt="Vacinas São Bento" />
        </div>
        <div className="menu">
          <button
            type="button"
            className="toogle-menu"
            onClick={handleMenu}
          >
            {!menuOpen ? <FiMenu size={20} /> : <FiX />}
          </button>
          <ul className={menuOpen ? "open" : ""}>
            <li className={currentPath === '/' ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/`}>
                <FiHome size={22} />
                Dashboard
              </Link>
            </li>

            <li className={currentPath.includes('/candidatos') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/candidatos`}>
                <FiUsers size={22} />
                Candidatos
              </Link>
            </li>

            <li className={currentPath.includes('/empresas') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/empresas`}>
                <VscOrganization size={22} />
                Empresas
              </Link>
            </li>

            <li className={currentPath.includes('/categorias-de-planos') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/categorias-de-planos`}>
                <FaMoneyCheck size={22} />
                Planos
              </Link>
            </li>

            <li className={currentPath.includes('/historico-pagamentos') ? 'active' : ''}>
              <Link to={`${process.env.PUBLIC_URL}/historico-pagamentos`}>
                <MdAttachMoney size={22} />
                Pagamentos
              </Link>
            </li>

            <li>
              <a href="/" onClick={() => signOut()}>
                <FiLogOut size={22} />
                Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default Menu;
