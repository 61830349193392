import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';

import { useToast } from '../../hooks/Toast';

import api, { bucketUrl } from '../../services/api';

import Input from '../../components/Form/Input';
import Button from '../../components/Button';

import { FormContainer, UserPic } from './styles';
import Main from '../../components/Main';

import { Candidate } from '../../interfaces/User';
import Textarea from '../../components/Form/Textarea';
import Personalizado from './_block';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const FormModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [user, setUser] = useState<Candidate | null>();
  const [sending, setSending] = useState<boolean>(false);
  const [showBlockForm, setShowBlockForm] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const history = useHistory();
  const { user_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (user_id && user_id !== 'novo') {
      api
        .get(`candidates/${user_id}`)
        .then(response => {
          const userAux = response.data;
          setUser({ ...userAux });
        })
        .catch(err => {
          console.log(err);
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
            timer: 6000
          });
        });
    }
  }, [addToast, user_id, refresh]);

  const unlock = useCallback(
    async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Desbloquear candidato?</h1>
              <p>Gostaria mesmo desbloquear o candidato {user?.name}?</p>
              <button onClick={onClose} type='button'>
                Não
              </button>
              <button
                type='button'
                onClick={async () => {
                  onClose();
                  setSending(true);
                  try {
                    await api.put(`candidates/${user?.id}/unlock`, {});
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: 'Candidato desbloqueado com sucesso.',
                    });
                    setSending(false);
                    setRefresh(!refresh);
                  } catch (err) {
                    setSending(false);
                    console.log(err);
                    if (err instanceof Yup.ValidationError) {
                      const errors = getValidationErrors(err);

                      formRef.current?.setErrors(errors);
                    } else {
                      addToast({
                        type: 'error',
                        title: 'Falha na requisição',
                        description:
                          'Ocorreu um erro, verifique sua conexão e tente novamente',
                      });
                    }
                  }
                }}
              >
                {sending ? "Enviando..." : "Sim"}
              </button>
            </div>
          );
        },
      });
    },
    [user, addToast, history, sending, refresh],
  );

  return (
    <>
      <Main>
        <FormContainer>
          <div className="breadcrumb">
            <h2>Início / {user ? 'Visualizar' : 'Novo'} candidato</h2>
          </div>

          <Form
            onSubmit={() => console.log('')}
            ref={formRef}
            initialData={user ? user : undefined}
          >
            <button
              type="button"
              onClick={() => history.go(-1)}
              className="close-btn"
            >
              <FiXCircle size={30} />
            </button>

            <UserPic>
              {user?.avatar ? (
                <a href={`${bucketUrl}/users/avatars/${user.avatar}`} target="_blank">
                  <img src={`${bucketUrl}/users/avatars/${user.avatar}`} alt="Preview" width="100" className="preview" />
                </a>
              ) : (
                <img src={'/default-user.png'} width="100" className="preview" />
              )}
            </UserPic>

            <Input name="name" label="Nome" fullWidth disabled />
            <Input name="email" label="E-mail" fullWidth disabled />

            <Input name="candidate.uf" label="Estado" fullWidth disabled />
            <Input name="candidate.city" label="Cidade" fullWidth disabled />
            <Input name="candidate.neighborhood" label="Bairro" fullWidth disabled />
            <Input name="candidate.address" label="Endereço" fullWidth disabled />
            <Input name="candidate.address_number" label="Número" fullWidth disabled />
            <Input name="candidate.complement" label="Complemento" fullWidth disabled />

            <Textarea name="candidate.mini_resume" label="Mini resumo" fullWidth disabled />
            <Textarea name="candidate.presentation_letter" label="Carta de apresentação" fullWidth disabled />

            <div className="break-line" />
            <div className="buttons">
              {
                user?.candidate?.blocked ? (
                  <Button type="button" onClick={() => unlock()} disabled={sending}>{sending ? "Desbloqueando..." : "Desbloquear"}</Button>
                ) : (
                  <Button type="button" onClick={() => setShowBlockForm(true)} disabled={sending}>{sending ? "Bloqueando..." : "Bloquear"}</Button>
                )
              }
            </div>
          </Form>
        </FormContainer>
      </Main>

      {
        showBlockForm && (
          <Personalizado id={user?.id} close={() => {
            setRefresh(!refresh);
            setShowBlockForm(false);
          }} />
        )
      }
    </>
  );
};

export default FormModal;
