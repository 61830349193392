import { createGlobalStyle, keyframes } from 'styled-components';

const shine = keyframes`
  0% {
    opacity: .4;
    top: 0%;
    left: 0%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 0.5s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: 0%;
    left: 50%;
    transition-property: left, top, opacity;
  }
`;

export default createGlobalStyle`
  @font-face {
    font-family: SegoeUI;
    src:
      local("Segoe UI Light"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2') format("woff2"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff') format("woff"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf') format("truetype");
    font-weight: 100;
  }

  @font-face {
    font-family: SegoeUI;
    src:
      local("Segoe UI Semilight"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff2') format("woff2"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.woff') format("woff"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semilight/latest.ttf') format("truetype");
    font-weight: 200;
  }

  @font-face {
    font-family: SegoeUI;
    src:
      local("Segoe UI"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2') format("woff2"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff') format("woff"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf') format("truetype");
    font-weight: 400;
  }

  @font-face {
    font-family: SegoeUI;
    src:
      local("Segoe UI Semibold"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff2') format("woff2"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.woff') format("woff"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/semibold/latest.ttf') format("truetype");
    font-weight: 500;
  }

  @font-face {
    font-family: SegoeUI;
    src:
      local("Segoe UI Bold"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff2') format("woff2"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.woff') format("woff"),
      url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/bold/latest.ttf') format("truetype");
    font-weight: 600;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #f1f1f1;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  body, input, button {
    font: 14px SegoeUI, sans-serif;
  }

  button {
    cursor: pointer;
  }

  .react-confirm-alert-overlay {
    background: rgb(0 0 0 / 90%);
  }

  .custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #e75e18;
    box-shadow: 0 3px 6px rgb(0 0 0 / 0.26);
    color: #fff;
    border-radius: 5px;

    > h1 {
      margin-top: 0;
      margin-bottom: 18px;
    }

    > p {
      margin-bottom: 13px;
    }

    > button {
      width: 160px;
      padding: 10px;
      border: 1px solid #fff;
      margin: 10px;
      cursor: pointer;
      background: none;
      color: #fff;
      font-size: 14px;
      transition: 0.3s;

      &:hover {
        background-color: #fff;
        color: #e75e18;
        box-shadow: 0 3px 6px rgba(0,0,0, 0.2);
      }
    }
  }



  .loading{
    position: relative;
    max-width: 100%;

    *:after {
      animation: ${shine} 1s ease-in-out infinite;
      animation-fill-mode: forwards;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      opacity: 0;
      transform: rotate(0);
      background: rgba(255, 255, 255, 0.8);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 40%,
        rgba(255, 255, 255, 0.30) 70%,
        rgba(255, 255, 255, 0.40) 82%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .buttons {
    width: 100%;
    text-align: right;
  }

  .no-javascript {
    position: fixed;
    top: 0;
    z-index: 999999;
    background: #c14545;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    padding: 5px;
  }

  .dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 120px;
    overflow: hidden;
    overflow-y: auto;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-top: 5px !important;
  }

  .dzu-inputLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Helvetica', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }

  .dzu-submitButtonContainer {
    display: none;
  }

  .dzu-previewContainer {
    padding: 15px 3%;
    margin-bottom: 0 !important;
  }

  .dzu-previewButton {
    filter: brightness(0) invert(1);
  }

  span.dzu-previewFileName {
    color: #fff;
  }

  .dzu-inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    padding: 0 14px;
    min-height: 32px;
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin: auto;
  }
`;
