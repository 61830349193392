import React, { useState, useCallback, useRef, useEffect, ChangeEvent } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../../hooks/Toast';

import Input from '../../../components/Form/Input';
import Button from '../../../components/Button';

import { FormContainer } from './styles';
import api, { bucketUrl } from '../../../services/api';
import { FiXCircle } from 'react-icons/fi';

import Main from '../../../components/Main';

import Select from '../../../components/Form/Select';
import NumberInput from '../../../components/Form/NumberInput';

const CustomPlan: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [sending, setSending] = useState<boolean>(false);
  const [isParcelable, setIsParcelable] = useState<boolean>(false);
  const [planCategory, setPlanCategory] = useState<any>();
  const [installmentsOptions, setInstallmentsOptions] = useState<any>([]);

  const history = useHistory();
  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id !== "formulario") {
      api.get(`/plan-categories/${entity_id}`)
        .then(res => {
          setPlanCategory(res.data.success.item);
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              error?.response?.data?.error?.error_message || 'Ocorreu um erro ao carregar informações, verifique sua conexão e tente novamente',
            timer: 6000
          });
        })
    }
  }, []);

  const handleSubmit = useCallback(async (data) => {
    setSending(true);

    try {
      let schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        period_tag: Yup.string().required('Campo obrigatório'),
        amount: Yup.string().required('Campo obrigatório'),
        number_of_views: Yup.string().required('Campo obrigatório'),
        number_of_users: Yup.string().required('Campo obrigatório'),
        days: Yup.string().required('Campo obrigatório'),
        payment_methods: Yup.string().required('Campo obrigatório'),
        installments: Yup.string().required('Campo obrigatório'),
        percentage_of_savings: Yup.string().nullable(),
        name_tag: Yup.string().required('Tag de nome obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      var res = await api.post(`/plan-categories/${entity_id}/plans`, data);

      addToast({
        type: 'success',
        title: 'Pronto!',
        description: res.data.success.message,
        timer: 4000
      });
      setSending(false);
      history.push(`/categorias-de-planos/${entity_id}/planos`);

    } catch (error) {
      setSending(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: 'Preencha todos os campos obrigatórios',
          timer: 6000
        });
        return;
      } else {
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            error?.response?.data?.error?.error_message || 'Ocorreu um erro ao enviar o formulário, verifique os dados e tente novamente',
          timer: 8000
        });
      }
    }
  }, [entity_id]);

  const handleInstallmentOptions = async () => {
    setTimeout(() => {
      const period_tag = formRef?.current?.getFieldValue('period_tag');

      switch (period_tag) {
        case 'Mensal':
          setInstallmentsOptions([
            { value: 1, label: '1' }
          ]);
          break;
        case 'Trimestral':
          setInstallmentsOptions([
            { value: 1, label: '1' },
            { value: 3, label: '3' },
          ]);
          break;
        case 'Semestral':
          setInstallmentsOptions([
            { value: 1, label: '1' },
            { value: 6, label: '6' },
          ]);
          break;
        case 'Anual':
          setInstallmentsOptions([
            { value: 1, label: '1' },
            { value: 12, label: '12' },
          ]);
          break;
        default:
          break;
      }
    }, 1000);
  };

  const handleInstallments = useCallback((selecteds) => {
    if (selecteds) {
      const has_boleto = selecteds.filter((aux: any) => aux.value === 'boleto');

      if (selecteds.length > 0 && has_boleto.length > 0) {
        setIsParcelable(false);
        formRef?.current?.setFieldValue('installments', { value: 1, label: '1' });
        setInstallmentsOptions([
          { value: 1, label: '1' }
        ]);
      } else if (selecteds.length > 0 && has_boleto.length === 0) {
        setIsParcelable(true);
        handleInstallmentOptions();
      }
    } else {
      formRef?.current?.clearField('installments');
      setIsParcelable(true);
      handleInstallmentOptions();
    }
  }, []);

  return (
    <>
      <Main>
        <FormContainer>
          <div className="breadcrumb">
            <h2>Configurando um novo plano na categoria "{planCategory?.name}"</h2>
          </div>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
          // initialData={item ? item : undefined}
          >
            <button type="button" onClick={() => history.go(-1)} className="close-btn">
              <FiXCircle size={30} />
            </button>

            <Input name="name" label="Nome do plano (Atenção! Sempre adicione identificadores no nome para manter a organização. Ex: Um plano mensal parcelado ficaria 'Plano Mensal Parc'.)" fullWidth />
            <Input name="name_tag" label="Tag de nome (Exemplo: start_mensal *OBS: Utilizar a mesma tag em todos os planos da mesma categoria.)" fullWidth />
            <Select
              name="period_tag"
              label="Tag de periodicidade"
              placeholder="Selecione a periodicidade do plano..."
              options={[
                { value: 'Mensal', label: 'Mensal' },
                { value: 'Trimestral', label: 'Trimestral' },
                { value: 'Semestral', label: 'Semestral' },
                { value: 'Anual', label: 'Anual' },
              ]}
              fullWidth
              isSearchable={false}
              onChange={(obj: any) => {
                if (obj) {
                  handleInstallmentOptions();

                  switch (obj.value) {
                    case 'Mensal':
                      formRef?.current?.setFieldValue('days', 30);
                      break;
                    case 'Trimestral':
                      formRef?.current?.setFieldValue('days', 90);
                      break;
                    case 'Semestral':
                      formRef?.current?.setFieldValue('days', 180);
                      break;
                    case 'Anual':
                      formRef?.current?.setFieldValue('days', 365);
                      break;
                    default:
                      break;
                  }
                }
              }}
            />
            <Input name="days" label="Periodicidade em dias" placeholder={`Selecione uma "Tag de Periodicidade"`} fullWidth type="number" disabled />
            <Input
              name={`amount`}
              label="Valor do plano (Atenção! O formato que deve ser enviado é 9990 para um resultado de R$ 99,90)"
              fullWidth
              type="number"
            />
            <Input name="number_of_views" label="Limite de visualizações de currículos" fullWidth type="number" />
            <Input name="number_of_users" label="Limite de usuários por empresa" fullWidth type="number" />
            <Select
              name="payment_methods"
              label="Métodos de pagamento"
              placeholder="Selecione os métodos de pagamento disponíveis para este plano..."
              options={[
                { value: 'credit_card', label: 'Cartão de crédito' },
                { value: 'boleto', label: 'Boleto' },
              ]}
              fullWidth
              isMulti
              isSearchable={false}
              onChange={(obj) => handleInstallments(obj)}
            />
            <Select
              name="installments"
              label="Parcelas disponíveis (Depende da periodicidade e dos métodos de pagamento. *OBS: Não é possível parcelamento com boleto)"
              options={installmentsOptions}
              placeholder="Selecione a quantidade de parcelas disponíveis para este plano..."
              fullWidth
              type="number"
              disabled={!isParcelable || installmentsOptions.length === 0}
              noOptionsMessage={() => "Antes, selecione uma tag de periodicidade"}
            />
            <Input name="percentage_of_savings" label="Percentual de economia ao contratar este plano (Gatilho de venda)" fullWidth type="number" step={'any'} />

            <div className="buttons">
              <Button type="submit" disabled={sending}>{sending ? "Publicando..." : "Publicar"}</Button>
            </div>
          </Form>
        </FormContainer>
      </Main>
    </>
  );
};

export default CustomPlan;
