import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router-dom';

import Table from '../../../components/Table';
import Pagination from '../../../components/Pagination';
import Main from '../../../components/Main';

import api from '../../../services/api';

import { Container } from './styles';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaCircle } from 'react-icons/fa';

const Plans: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [planCategory, setPlanCategory] = useState<any>();
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();
  const { entity_id } = useParams<any>();
  const { addToast } = useToast();

  useEffect(() => {
    if (entity_id !== "formulario") {
      api.get(`/plan-categories/${entity_id}`)
        .then(res => {
          setPlanCategory(res.data.success.item);
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              error?.response?.data?.error?.error_message || 'Ocorreu um erro ao carregar informações, verifique sua conexão e tente novamente',
            timer: 6000
          });
        })
    }
  }, []);

  useEffect(() => {
    setLoadingData(true);
    api
      .get(`/plan-categories/plans`, {
        params: {
          search: filteredAttr,
          page: currentPage,
          plan_category: entity_id
        }
      })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleStatus = useCallback((item: any) => {
    const title = item?.active ?
      "Desativar este plano?" :
      "Ativar este plano?";
    const description = item?.active ?
      `Gostaria mesmo de desativar o plano ${item?.name}? Lembre-se que ele ficará indisponível para novas contratações mas você pode reverter a ação posteriormente.` :
      `Gostaria mesmo de reativar o plano ${item?.name}? Lembre-se que ele ficará disponível novamente para novas contratações.`;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>{title}</h1>
            <p>{description}</p>
            <button onClick={onClose} type='button'>
              Não
            </button>
            <button
              type='button'
              onClick={async () => {
                api.put(`/plans/${item?.id}`, { active: !item?.active })
                  .then(res => {
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: "O status deste plano foi alterado com sucesso.",
                      timer: 6000
                    });
                    setRefresh(!refresh);
                    onClose();
                  })
                  .catch(error => {
                    addToast({
                      type: 'error',
                      title: 'Falha na requisição',
                      description:
                        error?.response?.data?.error?.error_message || 'Ocorreu um erro ao enviar a requisição, verifique sua conexão e tente novamente',
                      timer: 6000
                    });
                  });
              }}
            >
              Sim
            </button>
          </div>
        );
      },
    });
  }, [refresh]);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Todos os planos da categoria "{planCategory?.name}"</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar por plano"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
            <button
              type="button"
              className="new-btn"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/categorias-de-planos/${entity_id}/planos/formulario`)
              }
            >
              Novo
            </button>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Status</td>
                  <td>Nome do plano</td>
                  <td>Periodicidade</td>
                  <td>Parcelas</td>
                  <td>Valor</td>
                  <td>Métodos aceitos</td>
                  <td>Criado em</td>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={8}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item.id?.toString()}>
                            <td>
                              {
                                item?.active ?
                                  <FaCircle size={22} style={{ color: '#4aa334' }} /> :
                                  <FaCircle size={22} style={{ color: '#f74033' }} />
                              }
                            </td>
                            <td>{item?.name}</td>
                            <td>{item?.days} dias</td>
                            <td>{item?.installments}</td>
                            <td>{(item?.amount / 100).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}</td>
                            <td>{item?.payment_methods?.map((pm: string, index: number) => (
                              <>
                                {
                                  `${pm === "credit_card" ? "Cartão de Crédito" : "Boleto"}${index < item?.payment_methods?.length - 1 ? ", " : ""}`
                                }
                              </>
                            ))}</td>
                            <td>
                              {moment(item.created_at).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              <button
                                title={item?.active ? "Desativar" : "Ativar"}
                                onClick={() => handleStatus(item)}
                              >
                                {item?.active ? <FiEyeOff /> : <FiEye />}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          Nenhum resultado encontrado
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default Plans;
