import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  text-align: right;

  ul {
    display: flex;
    list-style: none;
    justify-content: flex-end;

    li {
      height: 35px;
      width: 40px;
      border: 1px solid #e75e18;
      cursor: pointer;
      color: #e75e18;
      font-weight: 500;
      border-radius: 2px;

      display: flex;
      align-items: center;
      justify-content: center;

      & + li {
        margin-left: 10px;
      }

      &.current {
        cursor: initial;
        pointer-events: none;
        color: #fff;
        background-color: #e75e18;
      }

      &.disabled {
        cursor: initial;
        pointer-events: none;
        color: #ccc;
        border-color: #ccc;
      }
    }
  }
`;
