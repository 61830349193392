import React, { useState, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Form/Input';
import Button from '../../components/Button';

import { FormContainer } from './styles';
import api, { bucketUrl } from '../../services/api';
import { FiXCircle } from 'react-icons/fi';

import Main from '../../components/Main';

import Select from '../../components/Form/Select';
import { useEffect } from 'react';
import Textarea from '../../components/Form/Textarea';
import { components } from 'react-select';

const PlanCategoryForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  // const [item, setItem] = useState<any>();
  const [sending, setSending] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [companiesSelectInitialLimit] = useState<number>(20);
  const [companiesOptions, setCompaniesOptions] = useState<any>([]);
  const [companiesOptionsDesc] = useState<any>([
    {
      value: 0,
      label:
        'Exibindo apenas os primeiros resultados. Digite para pesquisar mais cargos.',
    },
  ]);

  const history = useHistory();
  const { entity_id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    api.get(`/companies/all`)
      .then(res => {
        const aux = res.data.items.map((company: any) => {
          return {
            value: company.id,
            label: company.fantasy_name,
          }
        });
        setCompaniesOptions([...aux]);
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            error?.response?.data?.error?.error_message || 'Ocorreu um erro ao carregar informações, verifique sua conexão e tente novamente',
          timer: 6000
        });
      });
  }, []);

  useEffect(() => {
    if (entity_id !== "formulario") {
      api.get(`/plan-categories/${entity_id}`)
        .then(res => {
          var aux = res.data.success.item;

          setIsPublic(aux.is_public);

          formRef?.current?.setData({
            ...aux,
            companies: aux.companies.map((c: any) => {
              return { value: c.id, label: c.fantasy_name }
            }),
            is_public: { value: aux.is_public, label: aux.is_public ? 'Sim' : 'Não' }
          });
        })
        .catch(error => {
          addToast({
            type: 'error',
            title: 'Falha na requisição',
            description:
              error?.response?.data?.error?.error_message || 'Ocorreu um erro ao carregar informações, verifique sua conexão e tente novamente',
            timer: 6000
          });
        });
    }
  }, []);

  const handleSubmit = useCallback(async (data) => {
    setSending(true);

    try {
      let schema = Yup.object().shape({
        color: Yup.string().required('Cor obrigatória'),
        name: Yup.string().required('Nome obrigatório'),
        description: Yup.string().nullable(),
        benefit_01: Yup.string().nullable(),
        benefit_02: Yup.string().nullable(),
        benefit_03: Yup.string().nullable(),
        benefit_04: Yup.string().nullable(),
        benefit_05: Yup.string().nullable(),
        companies: data.is_public ? Yup.array() : Yup.array().min(1, "É necessário vincular pelo menos uma empresa"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      var res: any;
      if (entity_id === "formulario") {
        res = await api.post(`/plan-categories`, data);
      } else {
        res = await api.put(`/plan-categories/${entity_id}`, data);
      }

      addToast({
        type: 'success',
        title: 'Pronto!',
        description: res.data.success.message,
        timer: 4000
      });
      setSending(false);
      history.push("/categorias-de-planos");

    } catch (error) {
      setSending(false);
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: 'Preencha todos os campos obrigatórios',
          timer: 6000
        });
        return;
      } else {
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            error?.response?.data?.error?.error_message || 'Ocorreu um erro ao enviar o formulário, verifique os dados e tente novamente',
          timer: 6000
        });
      }
    }
  }, [entity_id]);

  const MenuList = (props: any): any => {
    return (
      <components.MenuList {...props}>
        {Array.isArray(props.children)
          ? props.children.slice(0, companiesSelectInitialLimit)
          : props.children}
      </components.MenuList>
    );
  };

  return (
    <>
      <Main>
        <FormContainer>
          <div className="breadcrumb">
            <h2>Início / Categorias de planos / Formulário</h2>
          </div>
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
          // initialData={item ? item : undefined}
          >
            <button type="button" onClick={() => history.go(-1)} className="close-btn">
              <FiXCircle size={30} />
            </button>

            <Input name="color" label="Cor de destaque da categoria" fullWidth type="color" />
            <Input name="name" label="Nome da categoria" fullWidth />
            <Textarea name="description" label="Descrição da categoria" fullWidth />
            <Input name="benefit_01" label="Benefício (01)" fullWidth step={'any'} />
            <Input name="benefit_02" label="Benefício (02)" fullWidth step={'any'} />
            <Input name="benefit_03" label="Benefício (03)" fullWidth step={'any'} />
            <Input name="benefit_04" label="Benefício (04)" fullWidth step={'any'} />
            <Input name="benefit_05" label="Benefício (05)" fullWidth step={'any'} />
            <Select
              name="is_public"
              label="Esta categoria é pública?"
              placeholder="Informe para quem esta categoria deve aparecer (No modo público ficará disponível para todas as empresas)"
              options={[
                { value: true, label: 'Sim' },
                { value: false, label: 'Não' },
              ]}
              isSearchable={false}
              onChange={(obj: any) => {
                if (obj) {
                  if (obj.value) {
                    setIsPublic(true);
                  } else {
                    setIsPublic(false);
                  }
                } else {
                  setIsPublic(false);
                }
              }}
              fullWidth
            />

            {!isPublic && (
              <Select
                name="companies"
                label="Vincule as empresas que terão acesso à esta categoria"
                placeholder="Selecione uma ou mais empresas"
                options={companiesOptionsDesc.concat(companiesOptions)}
                isSearchable
                fullWidth
                isMulti
                noOptionsMessage={() => "Sem resultados..."}
                isOptionDisabled={(opt) => opt.value === 0}
                components={{ MenuList }}
              />
            )}

            <div className="buttons">
              <Button type="submit" disabled={sending}>{sending ? "Salvando..." : "Salvar"}</Button>
            </div>
          </Form>
        </FormContainer>
      </Main>
    </>
  );
};

export default PlanCategoryForm;
