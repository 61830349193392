import { Form } from '@unform/web';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useHistory } from 'react-router-dom';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';

import Main from '../../components/Main';
import Table from '../../components/Table';
import api from '../../services/api';

import { Container, TopBlocks, PieChart, BarChart, LineChart, Filters } from './styles';

interface selectType {
  value: number;
  label: string;
}

const Dashboard: React.FC = () => {
  const [states] = useState(
    [
      { value: 'AC', label: 'AC' },
      { value: 'AL', label: 'AL' },
      { value: 'AP', label: 'AP' },
      { value: 'AM', label: 'AM' },
      { value: 'BA', label: 'BA' },
      { value: 'CE', label: 'CE' },
      { value: 'DF', label: 'DF' },
      { value: 'ES', label: 'ES' },
      { value: 'GO', label: 'GO' },
      { value: 'MA', label: 'MA' },
      { value: 'MT', label: 'MT' },
      { value: 'MS', label: 'MS' },
      { value: 'MG', label: 'MG' },
      { value: 'PA', label: 'PA' },
      { value: 'PB', label: 'PB' },
      { value: 'PR', label: 'PR' },
      { value: 'PE', label: 'PE' },
      { value: 'PI', label: 'PI' },
      { value: 'RJ', label: 'RJ' },
      { value: 'RN', label: 'RN' },
      { value: 'RS', label: 'RS' },
      { value: 'RO', label: 'RO' },
      { value: 'RR', label: 'RR' },
      { value: 'SC', label: 'SC' },
      { value: 'SP', label: 'SP' },
      { value: 'SE', label: 'SE' },
      { value: 'TO', label: 'TO' },
    ]
  );

  const [totalCandidates, setTotalCandidates] = useState();
  const [totalCompanies, setTotalCompanies] = useState();
  const [totalCompanyPayments, setTotalCompanyPayments] = useState();
  const [totalSignatures, setTotalSignatures] = useState();
  const [totalVacancies, setTotalVacancies] = useState();
  const [candidatesPerUf, setCandidatesPerUf] = useState<any>();
  const [companiesPerUf, setCompaniesPerUf] = useState<any>();
  const [vacanciesPerUf, setVacanciesPerUf] = useState<any>();

  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [loadingCharts, setLoadingCharts] = useState(true);
  const [canLoad, setCanLoad] = useState(true);
  const history = useHistory();
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateUntil, setDateUntil] = useState<any>(null);

  const [candidateUf, setCandidateUf] = useState<string>('');
  const [companyUf, setCompanyUf] = useState<string>('');
  const [vacancyUf, setVacancyUf] = useState<string>('');

  useEffect(() => {
    api
      .get(`/numbers-data`, {
        params: {
          dateFrom,
          dateUntil,
          candidateUf,
          companyUf,
          vacancyUf
        }
      })
      .then(response => {
        setCandidatesPerUf(response.data.candidates_per_uf);
        setCompaniesPerUf(response.data.companies_per_uf);
        setVacanciesPerUf(response.data.vacancies_per_uf);
        setTotalCandidates(response.data.total_candidates.total);
        setTotalCompanies(response.data.total_companies.total);
        setTotalCompanyPayments(response.data.total_company_payments.total);
        setTotalSignatures(response.data.total_signatures.total);
        setTotalVacancies(response.data.total_vacancies.total);
      });
  }, [dateFrom, dateUntil, companyUf, candidateUf, vacancyUf]);

  // loadCharts
  useEffect(() => {
    if (canLoad) {
      setLoadingCharts(true);
      api
        .get(`pie-chart-data`, {
          params: {
            dateFrom,
            dateUntil,
          }
        })
        .then(response => {
          setPieData(response.data.results);
        });

      api
        .get(`column-chart-data`, {
          params: {
            dateFrom,
            dateUntil,
          }
        })
        .then(response => {
          setBarData(response.data.results);
        });

      api
        .get(`line-chart-data`, {
          params: {
            dateFrom,
            dateUntil,
          }
        })
        .then(response => {
          setLineData(response.data.results);
          setTimeout(() => {
            setLoadingCharts(false);
          }, 500);
        });
    }
  }, [dateFrom, dateUntil]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <div>
            <h2>Dashboard</h2>
          </div>
        </div>

        <Table>
          <div className="table-container">
            <table className="dashboard">
              <thead>
                <tr>
                  <td></td>
                  {
                    states.map((state) => (
                      <td style={{ width: 'auto' }}>{state.value}</td>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {loadingCharts ? (
                  <tr>
                    <td colSpan={states.length + 1}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className="dashboard">
                        <strong>
                          Candidatos
                        </strong>
                      </td>

                      {states.map(state => (
                        <td key={state.value?.toString()}>
                          {candidatesPerUf ?
                            candidatesPerUf.filter((item: any) => item.uf === state.value)[0]?.total || 0
                            : 0}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="dashboard">
                        <strong>
                          Empresas
                        </strong>
                      </td>

                      {states.map(state => (
                        <td key={state.value?.toString()}>
                          {companiesPerUf ?
                            companiesPerUf.filter((item: any) => item.uf === state.value)[0]?.total || 0
                            : 0}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td className="dashboard">
                        <strong>
                          Vagas
                        </strong>
                      </td>

                      {states.map(state => (
                        <td key={state.value?.toString()}>
                          {vacanciesPerUf ?
                            vacanciesPerUf.filter((item: any) => item.uf === state.value)[0]?.total || 0
                            : 0}
                        </td>
                      ))}
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Table>

        <TopBlocks>
          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  {/* <Form onSubmit={console.log}>
                    <Select
                      name="candidate_uf"
                      options={states}
                      placeholder="Filtre por UF"
                      isClearable
                      onChange={(obj: any) => {
                        if (obj) {
                          setCandidateUf(obj.value);
                        } else {
                          setCandidateUf('');
                        }
                      }}
                      fullWidth
                    />
                  </Form> */}

                  <h2>{totalCandidates}</h2>
                  <h4>Currículos cadastrados</h4>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  {/* <Form onSubmit={console.log}>
                    <Select
                      name="company_uf"
                      options={states}
                      placeholder="Filtre por UF"
                      isClearable
                      onChange={(obj: any) => {
                        if (obj) {
                          setCompanyUf(obj.value);
                        } else {
                          setCompanyUf('');
                        }
                      }}
                      fullWidth
                    />
                  </Form> */}

                  <h2>{totalCompanies}</h2>
                  <h4>Empresas cadastradas</h4>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  {/* <Form onSubmit={console.log}>
                    <Select
                      name="vacancy_uf"
                      options={states}
                      placeholder="Filtre por UF"
                      isClearable
                      onChange={(obj: any) => {
                        if (obj) {
                          setVacancyUf(obj.value);
                        } else {
                          setVacancyUf('');
                        }
                      }}
                      fullWidth
                    />
                  </Form> */}

                  <h2>{totalVacancies}</h2>
                  <h4>Vagas publicadas</h4>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  <h2>{totalSignatures}</h2>
                  <h4>Assinaturas ativas</h4>
                </div>
              </>
            )}
          </div>

          <div className="block">
            {loadingCharts ? (
              <div className="loading">
                <h2 />
                <h4 />
                <p />
              </div>
            ) : (
              <>
                <div>
                  <h2>{totalCompanyPayments}</h2>
                  <h4>Pagamentos efetuados</h4>
                </div>
              </>
            )}
          </div>
        </TopBlocks>

        <div className="row">
          <PieChart>
            <h3>Aderência de planos por período</h3>
            {loadingCharts ? (
              <div className="loading">
                <div className="chart" />
              </div>
            ) : (
              <>
                {pieData.length > 0 ? (
                  <div className="chart-box">
                    <Chart
                      width="100%"
                      height="300px"
                      chartType="PieChart"
                      data={pieData}
                      options={{
                        slices: {
                          0: { color: '#e75e18' },
                          1: { color: '#cf794e' },
                          2: { color: '#dd9978' },
                          3: { color: '#ffc8ad' },
                        },
                        pieHole: 0.4,
                        legend: {
                          position: 'bottom',
                        },
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </div>
                ) : (
                  <p className="alert">Nenhum resultado encontrado</p>
                )}
              </>
            )}
          </PieChart>

          <BarChart>
            <h3>Novas assinaturas por mês</h3>
            {loadingCharts ? (
              <div className="loading">
                <div className="chart" />
              </div>
            ) : (
              <div className="chart-box">
                <Chart
                  width="100%"
                  height="300px"
                  chartType="Bar"
                  data={barData}
                  rootProps={{ 'data-testid': '2' }}
                  options={{
                    legend: { position: 'none' },
                    colors: ["#ef824b"],
                  }}
                />
              </div>
            )}
          </BarChart>
        </div>

        <LineChart>
          <h3>Novas assinaturas nos últimos 30 dias</h3>
          {loadingCharts ? (
            <div className="loading">
              <div className="chart" />
            </div>
          ) : (
            <div className="chart-box">
              <Chart
                width="100%"
                height="300px"
                chartType="AreaChart"
                data={lineData}
                rootProps={{ 'data-testid': '3' }}
                options={{
                  legend: { position: 'none' },
                  colors: ["#ef824b"],
                  chartArea: { width: "100%", height: "60%", left: "40" },
                  series: {
                    0: {
                      lineWidth: 2,
                      areaOpacity: 0.4,
                    }
                  },
                  tooltip: { textStyle: { fontSize: 10 } },
                  hAxis: { textStyle: { fontSize: 10 } },
                  vAxis: { textStyle: { fontSize: 10 } },
                }}
              />
            </div>
          )}
        </LineChart>
      </Container>
    </Main >
  );
};

export default Dashboard;
