import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  width: 600px;
  max-width: 90%;
  padding: 30px 40px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;

  @media (max-width: 769px) {
    padding-top: 60px;
  }

  button.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border-color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    border-width: 1px;
  }

  h2 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 22px;
    width: 100%;

    @media (max-width: 769px) {
      font-size: 1rem;
      margin-top: 10px;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;
`;

export default Container;
