import React from 'react';
import { Router, Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';

import Dashboard from '../pages/Dashboard';
import Candidates from '../pages/Candidates';
import CandidateForm from '../pages/Candidates/form';
import Companies from '../pages/Companies';
import CompanyForm from '../pages/Companies/form';
import ResetPassword from '../pages/ResetPassword';
import Payments from '../pages/Payments';
import PlanCategories from '../pages/PlanCategories';
import PlanCategoriesForm from '../pages/PlanCategories/form';
import Plans from '../pages/PlanCategories/Plans';
import CustomPlan from '../pages/PlanCategories/Plans/form';

const Routes: React.FC = () => (
  <Switch>
    <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />

    <Route
      path={`${process.env.PUBLIC_URL}/`}
      component={Dashboard}
      exact
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/candidatos`}
      component={Candidates}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/candidatos/:user_id`}
      component={CandidateForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/candidatos/novo`}
      component={CandidateForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/empresas`}
      component={Companies}
      isPrivate
      isAdmin
      exact
    />
    <Route
      path={`${process.env.PUBLIC_URL}/empresas/:id`}
      component={CompanyForm}
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/empresas/novo`}
      component={CompanyForm}
      isPrivate
      isAdmin
    />

    <Route
      path={`${process.env.PUBLIC_URL}/recuperar-senha`}
      component={ResetPassword}
    />

    <Route
      path={`${process.env.PUBLIC_URL}/historico-pagamentos`}
      component={Payments}
      isPrivate
      isAdmin
    />

    {/* Rotas relacionadas aos planos e suas categorias */}
    <Route
      path={`${process.env.PUBLIC_URL}/categorias-de-planos`}
      component={PlanCategories}
      exact
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/categorias-de-planos/:entity_id`}
      component={PlanCategoriesForm}
      exact
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/categorias-de-planos/formulario`}
      component={PlanCategoriesForm}
      exact
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/categorias-de-planos/:entity_id/planos`}
      component={Plans}
      exact
      isPrivate
      isAdmin
    />
    <Route
      path={`${process.env.PUBLIC_URL}/categorias-de-planos/:entity_id/planos/formulario`}
      component={CustomPlan}
      exact
      isPrivate
      isAdmin
    />
    {/* FIM das Rotas relacionadas aos planos e suas categorias */}
  </Switch>
);

export default Routes;
