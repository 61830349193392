import styled, { keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  height: 100vh;
  min-width: 170px;

  @media (max-width: 1024px) {
    height: 60px;
  }

  @media print {
    display: none;
  }

  .fixed {
    position: fixed;
    z-index: 2;
    height: 100vh;
    width: 170px;
    animation: ${appearFromLeft} 1s;
    background: #e75e18;

    @media (max-width: 1024px) {
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .logo {
      height: 80px;
      display: flex;
      place-content: center;
      place-items: center;
      width: 100%;
      margin-top: 40px;

      @media (max-width: 1024px) {
        margin-top: 0px;
        height: 100%;
        width: auto;

        img {
          height: 60px;
          object-fit: contain;
          padding: 5px 0;
        }
      }

      img {
        max-width: 75%;
      }
    }

    .menu {
      .toogle-menu {
        display: none;
        margin: 8px 0;
        background: transparent;
        border: 2px solid #fff;
        width: 40px;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 3px;
        height: 32px;
      }

      ul {
        padding: 40px 0 0 0;
        list-style: none;

        li {
          width: 100%;
          padding: 13px 15px;
          margin-bottom: 10px;

          a {
            width: 100%;
            display: block;
            color: #fff;
            text-decoration: none;
            line-height: 25px;
            font-size: 14px;
            font-weight: 500;

            svg {
              float: left;
              line-height: 23px;
              margin-right: 10px;
            }
          }

          &.active {
            background-color: #ef824b;
          }
        }
      }

      @media (max-width: 1024px) {
        background: transparent;

        ul {
          display: flex;
          padding: 12px 0;

          li {
            margin-bottom: 0;

            & + li {
              margin-left: 20px;
            }

            a {
              display: flex;
              color: #313131;

              svg {
                margin-right: 5px;
              }
            }

            &.active {
              a {
                color: #fff;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        .toogle-menu {
          display: flex;
        }

        ul {
          position: absolute;
          right: 0;
          flex-direction: column;
          background: #fff;
          margin-top: 6px;
          width: 100%;
          box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.16);
          padding: 0;
          max-height: 0;
          transition: max-height 0.4s;
          overflow: hidden;

          &.open {
            max-height: 260px !important;
          }

          li {
            padding: 13px 0 13px 20px;
            margin: 0 !important;
            border-top: 1px solid #ddd;
          }
        }
      }
    }
  }
`;
