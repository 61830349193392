import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 769px) {
    padding: 0 15px;
  }
`;

export const FormContainer = styled.div`
  padding: 0 50px;
  place-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 15px;
  }

  @media (max-width: 769px) {
    padding: 0 15px;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin-bottom: 30px;

    .close-btn {
      position: absolute;
      right: 0;
      top: -50px;
      padding: 0;
      line-height: 20px;
      background: transparent;
      border: 0;
      color: #e75e18;
    }

    button[type='submit'] {
      border-radius: 5px;
    }

    button {
      border-radius: 5px;
      /* max-width: 48%; */

      &.confirm {
        background-color: #40c77c;
        border-color: #40c77c;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#40c77c')};
        }
      }

      &.cancel {
        background-color: #c53030;
        border-color: #c53030;

        &:hover {
          color: #fff;
          background: ${shade(0.2, '#c53030')};
        }
      }
    }

    .to-right {
      @media (max-width: 769px) {
        /* max-width: 48%; */

        button {
          max-width: 100%;
          width: 237px;
        }
      }
    }

    button + button {
      margin-right: 15px;
    }
  }

  .alert-box {
    background: #ddd;
    padding: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`;
