import React, { useEffect, useState, useCallback } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';

import api, { bucketUrl } from '../../services/api';

import Main from '../../components/Main';
import { Container } from './styles';
import { Candidate } from '../../interfaces/User';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const Candidates: React.FC = () => {
  const [items, setItems] = useState<Candidate[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('candidates', {
        params: {
          search: filteredAttr,
          page: currentPage,
        },
      })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage]);

  const handleEdit = useCallback(
    user => {
      history.push(`${process.env.PUBLIC_URL}/candidatos/${user.id}`);
    },
    [history],
  );

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Candidatos</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar usuários"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
            {/* <button
              type="button"
              className="new-btn"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/candidatos/novo`)
              }
            >
              Novo
            </button> */}
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Avatar</td>
                  <td>Nome</td>
                  <td>E-mail</td>
                  <td>Telefone</td>
                  <td>Status</td>
                  <td>Versão termo de uso</td>
                  <td>Visualizar</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={7}>
                      Carregando...
                    </td>
                  </tr>
                ) : (
                  <>
                    {
                      items.length > 0 ?
                        items.map(user => (
                          <tr key={user.id.toString()}>
                            <td>
                              {
                                user.avatar ? (
                                  <a href={`${bucketUrl}/users/avatars/${user.avatar}`} target="_blank">
                                    <img src={user.avatar ? `${bucketUrl}/users/avatars/${user.avatar}` : '/default-user.png'} />
                                  </a>
                                ) : (
                                  <img src={'/default-user.png'} />
                                )
                              }
                            </td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user?.candidate?.phone_opt1 ? user?.candidate.phone_opt1 : "Não informado"}</td>
                            <td>{user?.candidate?.blocked ?
                              "Bloqueado" : user?.candidate?.active ?
                                "Ativo" : "Inativo"}</td>
                            <td>{user?.candidate?.candidate_accepted_terms && user?.candidate?.candidate_accepted_terms.length > 0 ?
                              user?.candidate?.candidate_accepted_terms[0].term_version : "-"}</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => handleEdit(user)}
                              >
                                <FiEdit />
                              </button>
                            </td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan={7}>
                              Nenhum resultado encontrado
                              </td>
                          </tr>
                        )
                    }
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>
        </Table>
      </Container>
    </Main>
  );
};

export default Candidates;
