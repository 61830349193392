import React, { useState, useCallback, useRef, useEffect, ChangeEvent } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/Toast';

import Input from '../../components/Form/Input';
import Button from '../../components/Button';

import { FormContainer, UserPic } from './styles';
import api, { bucketUrl } from '../../services/api';
import { FiXCircle } from 'react-icons/fi';

import Main from '../../components/Main';

import { Company } from '../../interfaces/Company';
import Textarea from '../../components/Form/Textarea';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Personalizado from './_block';
import { MdAddCircleOutline } from 'react-icons/md';

const FormModal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [item, setItem] = useState<Company | null>(null);
  const [sending, setSending] = useState<boolean>(false);
  const [addingFreeDays, setAddingFreeDays] = useState<boolean>(false);
  const [addingFreeCredits, setAddingFreeCredits] = useState<boolean>(false);
  const [showBlockForm, setShowBlockForm] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const history = useHistory();
  const { id } = useParams<any>();

  const { addToast } = useToast();

  useEffect(() => {
    api
      .get(`companies/${id}`)
      .then(response => {
        setItem({
          ...response.data,
          has_active_plan: response.data.has_active_plan ? "Sim" : "Não",
          created_at: moment(response.data.created_at).format('YYYY-MM-DD')
        });
      })
      .catch(err => {
        console.log(err);
        addToast({
          type: 'error',
          title: 'Falha na requisição',
          description:
            'Ocorreu um erro ao carregar os dados, verifique sua conexão e tente novamente.',
        });
      });
  }, [refresh]);

  const unlock = useCallback(
    async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Desbloquear empresa?</h1>
              <p>Gostaria mesmo desbloquear a empresa {item?.fantasy_name}?</p>
              <button onClick={onClose} type='button'>
                Não
              </button>
              <button
                type='button'
                onClick={async () => {
                  onClose();
                  setSending(true);
                  try {
                    await api.put(`companies/${item?.id}/unlock`, {});
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: 'Empresa desbloqueado com sucesso.',
                    });
                    setSending(false);
                    setRefresh(!refresh);
                  } catch (err) {
                    setSending(false);
                    console.log(err);
                    if (err instanceof Yup.ValidationError) {
                      const errors = getValidationErrors(err);

                      formRef.current?.setErrors(errors);
                    } else {
                      addToast({
                        type: 'error',
                        title: 'Falha na requisição',
                        description:
                          'Ocorreu um erro, verifique sua conexão e tente novamente',
                      });
                    }
                  }
                }}
              >
                {sending ? "Enviando..." : "Sim"}
              </button>
            </div>
          );
        },
      });
    },
    [item, addToast, history, sending, refresh],
  );

  const addFreeDays = useCallback(
    async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Atenção!</h1>
              <p>Esta ação adiciona 30 dias no prazo de vencimento do plano contratado pela empresa.<br />
                Gostaria mesmo de presentear {item?.fantasy_name} com esta bonificação de adição de 30 dias gratuitamente?
              </p>
              <button onClick={onClose} type='button'>
                Cancelar
              </button>
              <button
                type='button'
                onClick={async () => {
                  onClose();
                  setAddingFreeDays(true);
                  try {
                    await api.put(`companies/${item?.id}/addfreedays`, {});
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: 'Ação realizada com sucesso.',
                    });
                    setAddingFreeDays(false);
                    setRefresh(!refresh);
                  } catch (err) {
                    setAddingFreeDays(false);
                    console.log(err);
                    if (err instanceof Yup.ValidationError) {
                      const errors = getValidationErrors(err);

                      formRef.current?.setErrors(errors);
                    } else {
                      addToast({
                        type: 'error',
                        title: 'Falha na requisição',
                        description:
                          'Ocorreu um erro, verifique sua conexão e tente novamente',
                      });
                    }
                  }
                }}
              >
                {addingFreeDays ? "Confirmando..." : "Confirmar"}
              </button>
            </div>
          );
        },
      });
    },
    [addingFreeDays, item],
  );

  const addFreeCredits = useCallback(
    async () => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Atenção!</h1>
              <p>Esta ação adiciona 10 créditos de visualização de currículo no mês corrente do plano contratado pela empresa.<br />
                Gostaria mesmo de presentear {item?.fantasy_name} com esta bonificação de 10 créditos?
              </p>
              <button onClick={onClose} type='button'>
                Cancelar
              </button>
              <button
                type='button'
                onClick={async () => {
                  onClose();
                  setAddingFreeCredits(true);
                  try {
                    await api.put(`companies/${item?.id}/addfreecredits`, {});
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: 'Ação realizada com sucesso.',
                    });
                    setAddingFreeCredits(false);
                    setRefresh(!refresh);
                  } catch (err) {
                    setAddingFreeCredits(false);
                    console.log(err);
                    if (err instanceof Yup.ValidationError) {
                      const errors = getValidationErrors(err);

                      formRef.current?.setErrors(errors);
                    } else {
                      addToast({
                        type: 'error',
                        title: 'Falha na requisição',
                        description:
                          'Ocorreu um erro, verifique sua conexão e tente novamente',
                      });
                    }
                  }
                }}
              >
                {addingFreeCredits ? "Confirmando..." : "Confirmar"}
              </button>
            </div>
          );
        },
      });
    },
    [addingFreeCredits, item],
  );

  return (
    <>
      <Main>
        <FormContainer>
          <div className="breadcrumb">
            <h2>Início / Visualizar empresa</h2>
          </div>
          <Form
            onSubmit={console.log}
            ref={formRef}
            initialData={item ? item : undefined}
          >
            <button type="button" onClick={() => history.go(-1)} className="close-btn">
              <FiXCircle size={30} />
            </button>
            <UserPic>
              {item?.logo ? (
                <a href={`${bucketUrl}/companies/logo/${item.logo}`} target="_blank">
                  <img src={`${bucketUrl}/companies/logo/${item.logo}`} alt="Preview" width="100" className="preview" />
                </a>
              ) : (
                <img src={'/default-user.png'} width="100" className="preview" />
              )}
            </UserPic>

            <Input name="fantasy_name" label="Nome fantasia" fullWidth disabled />
            <Input name="company_name" label="Razão social" fullWidth disabled />
            <Input name="cnpj" label="CNPJ" fullWidth disabled />
            <Input name="responsible_email" label="E-mail do responsável" fullWidth disabled />
            <Input name="has_active_plan" label="Plano ativo" fullWidth disabled />
            <Input type="date" name="created_at" label="Registrada em" fullWidth disabled />

            <Input name="uf" label="Estado" fullWidth disabled />
            <Input name="city" label="Cidade" fullWidth disabled />
            <Input name="neighborhood" label="Bairro" fullWidth disabled />
            <Input name="address" label="Endereço" fullWidth disabled />
            <Input name="address_number" label="Número" fullWidth disabled />
            <Input name="complement" label="Complemento" fullWidth disabled />

            <Textarea name="description" label="Descrição" fullWidth disabled />

            <Input name="total_credits" label="Créditos de visualização restantes" fullWidth disabled />

            <div className="buttons">
              <div className="to left">
                <Button
                  type="button"
                  title="Acrescentar mais 10 créditos no plano desta empresa gratuitamente"
                  onClick={() => addFreeCredits()}
                  disabled={addingFreeCredits}
                >
                  <MdAddCircleOutline />
                  {addingFreeCredits ? "Carregando..." : "Bonificar em 10 créditos"}
                </Button>

                <Button
                  type="button"
                  title="Acrescentar mais 30 dias no plano desta empresa gratuitamente"
                  onClick={() => addFreeDays()}
                  disabled={addingFreeDays}
                >
                  <MdAddCircleOutline />
                  {addingFreeDays ? "Carregando..." : "Bonificar em 30 dias"}
                </Button>
              </div>

              <div className="to right">
                {
                  item?.blocked ? (
                    <Button type="button" onClick={() => unlock()} disabled={sending}>{sending ? "Desbloqueando..." : "Desbloquear"}</Button>
                  ) : (
                    <Button type="button" onClick={() => setShowBlockForm(true)} disabled={sending}>{sending ? "Bloqueando..." : "Bloquear"}</Button>
                  )
                }
              </div>
            </div>
          </Form>
        </FormContainer>
      </Main>
      {
        showBlockForm && (
          <Personalizado id={item?.id} close={() => {
            setRefresh(!refresh);
            setShowBlockForm(false);
          }} />
        )
      }
    </>
  );
};

export default FormModal;
