import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';

import api from '../../services/api';

import { Container } from './styles';
import moment from 'moment';

const Payments: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setLoadingData(true);
    api
      .get(`companies-payments`, { params: { search: filteredAttr, page: currentPage } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage]);

  const handleEdit = useCallback((item: any) => {
    history.push(`${process.env.PUBLIC_URL}/empresas/${item.id}`);
  }, []);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Histórico de pagamentos</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar por empresa"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Nome fantasia</td>
                  <td>Plano</td>
                  <td>Período</td>
                  <td>Valor</td>
                  <td>Parcelas</td>
                  <td>Método</td>
                  <td>Data</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={7}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item?.id?.toString()}>
                            <td>{item?.company.fantasy_name}</td>
                            <td>{item?.signature?.plan?.name}</td>
                            <td>{item?.signature?.plan?.days}</td>
                            <td>{(item?.amount / 100).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}</td>
                            <td>{item?.installments}</td>
                            <td>{item?.payment_method === "credit_card" ? "Cartão de crédito" : "Boleto"}</td>
                            <td>
                              {moment(item.created_at).format('DD/MM/YYYY')}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          Nenhum resultado encontrado
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default Payments;
