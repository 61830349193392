import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiX } from 'react-icons/fi';
import * as Yup from 'yup';

import Button from '../../../components/Button';
import Textarea from '../../../components/Form/Textarea';

import { useToast } from '../../../hooks/Toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import api from '../../../services/api';
import Container, { Overlay } from './styles';

interface CompProps {
  id?: number;
  close(): void;
}

const Personalizado: React.FC<CompProps> = ({ close, id }) => {
  const [sending, setSending] = useState<boolean>(false);

  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data) => {
    setSending(true);
    try {
      const schema = Yup.object().shape({
        block_reason: Yup.string().required('Descreva o motivo do bloqueio.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api
        .put(`candidates/${id}/block`, { block_reason: data.block_reason })
        .then((resp) => {
          setSending(false);
          addToast({
            type: 'success',
            title: 'Pronto!',
            description: resp.data.success.message,
            timer: 7000,
          });
          close();
        })
        .catch((err) => {
          setSending(false);
          addToast({
            type: 'error',
            title: 'Oops!',
            description: `Ocorreu um erro, verifique sua conexão e tente novamente.`,
          });
        });
    } catch (err) {
      setSending(false);
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Oops!',
          description: `Ocorreu um erro, verifique sua conexão e tente novamente.`,
        });
      }
    }
  }, []);

  return (
    <Overlay>
      <Container>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <button className='close-btn' onClick={() => close()}>
            <FiX size={17} color='#333' />
          </button>

          <h2 className='sub-title'>Confirmação de bloqueio de conta</h2>

          <Textarea name='block_reason' label='Descreva o motivo do bloqueio' fullWidth />

          <Button type='submit' disabled={sending}>
            {sending ? 'Bloqueando...' : 'Bloquear'}
          </Button>
        </Form>
      </Container>
    </Overlay>
  );
};

export default Personalizado;
