import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit } from 'react-icons/bi';
import { NavLink, useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';

import api from '../../services/api';

import { Container } from './styles';
import moment from 'moment';
import { FiEdit, FiEye, FiEyeOff, FiMenu } from 'react-icons/fi';
import { useToast } from '../../hooks/Toast';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FaCircle } from 'react-icons/fa';

const PlanCategories: React.FC = () => {
  const [items, setItems] = useState<any[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    setLoadingData(true);
    api
      .get(`plan-categories`, { params: { search: filteredAttr, page: currentPage } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage, refresh]);

  const handleStatus = useCallback((item: any) => {
    const title = item?.active ?
      "Desativar categoria?" :
      "Ativar categoria?";
    const description = item?.active ?
      `Gostaria mesmo de desativar a categoria de planos ${item?.name}? Lembre-se que todos os planos desta categoria ficarão indisponíveis para novas contratações mas você pode reverter a ação posteriormente.` :
      `Gostaria mesmo de reativar a categoria de planos ${item?.name}? Lembre-se que todos os planos desta categoria ficarão disponíveis para novas contratações.`;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>{title}</h1>
            <p>{description}</p>
            <button onClick={onClose} type='button'>
              Não
            </button>
            <button
              type='button'
              onClick={async () => {
                setChangingStatus(true);
                api.put(`/plan-categories/${item?.id}`, { active: !item?.active })
                  .then(res => {
                    addToast({
                      type: 'success',
                      title: 'Pronto!',
                      description: "O status da categoria foi alterado com sucesso.",
                      timer: 6000
                    });
                    setRefresh(!refresh);
                    setChangingStatus(false);
                    onClose();
                  })
                  .catch(error => {
                    setChangingStatus(false);
                    addToast({
                      type: 'error',
                      title: 'Falha na requisição',
                      description:
                        error?.response?.data?.error?.error_message || 'Ocorreu um erro ao enviar a requisição, verifique sua conexão e tente novamente',
                      timer: 6000
                    });
                  });
              }}
            >
              {changingStatus ? "Enviando..." : "Sim"}
            </button>
          </div>
        );
      },
    });
  }, [refresh, changingStatus]);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Visualizando todas as categorias de planos</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar por categoria"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
            <button
              type="button"
              className="new-btn"
              onClick={() =>
                history.push(`${process.env.PUBLIC_URL}/categorias-de-planos/formulario`)
              }
            >
              Nova
            </button>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <td>Status</td>
                  <td>Nome da categoria</td>
                  <td>Exibição</td>
                  <td>Criado em</td>
                  <td>Total planos</td>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={6}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item.id?.toString()}>
                            <td>
                              {
                                item?.active ?
                                  <FaCircle size={22} style={{ color: '#4aa334' }} /> :
                                  <FaCircle size={22} style={{ color: '#f74033' }} />
                              }
                            </td>
                            <td>{item?.name}</td>
                            <td>{item?.is_public ? "Pública" : "Customizada"}</td>
                            <td>
                              {moment(item.created_at).format('DD/MM/YYYY')}
                            </td>
                            <td>{item?.__meta__?.plans_count}</td>
                            <td>
                              <NavLink to={`/categorias-de-planos/${item?.id}/planos`}>
                                <button title="Ver planos desta categoria"><FiMenu /></button>
                              </NavLink>

                              <button
                                title={item?.active ? "Desativar" : "Ativar"}
                                onClick={() => handleStatus(item)}
                              >
                                {item?.active ? <FiEyeOff /> : <FiEye />}
                              </button>

                              <NavLink to={`/categorias-de-planos/${item?.id}`}>
                                <button title="Editar categoria"><FiEdit /></button>
                              </NavLink>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          Nenhum resultado encontrado
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main>
  );
};

export default PlanCategories;
