import React, { useEffect, useState, useCallback } from 'react';
import { BiEdit } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Main from '../../components/Main';

import api, { bucketUrl } from '../../services/api';

import { Container } from './styles';
import moment from 'moment';
import { Company } from '../../interfaces/Company';

const Companies: React.FC = () => {
  const [items, setItems] = useState<Company[]>([]);
  const [filteredAttr, setFilteredAttr] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const history = useHistory();

  useEffect(() => {
    setLoadingData(true);
    api
      .get('companies', { params: { search: filteredAttr, page: currentPage } })
      .then(response => {
        setItems(response.data.items);
        setLoadingData(false);
        setTotalPages(response.data.last_page);
      });
  }, [filteredAttr, currentPage]);

  const getPlanStatus = (status: string) => {

    var statuses: any = {
      'ended': "Encerrado",
      'paid': "Pago",
      'canceled': "Cancelado",
      'pending_payment': "Pagamento pendente",
      'unpaid': 'Não pago',
      'trialing': "Modo de teste",
    };

    return statuses[status];
  };

  const handleEdit = useCallback((item: Company) => {
    history.push(`${process.env.PUBLIC_URL}/empresas/${item.id}`);
  }, []);

  let timeout: any;
  const handleSearchInput = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilteredAttr(value);
    }, 1000);
  }, [timeout]);

  return (
    <Main>
      <Container>
        <div className="breadcrumb">
          <h2>Início &gt; Empresas</h2>
        </div>

        <Table>
          <div className="filters to-right">
            <input
              type="text"
              name="search"
              placeholder="Filtrar por nome"
              onChange={event => handleSearchInput(event.target.value)}
              className="filter-input"
            />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {/* <td>Logo</td> */}
                  <td>Nome fantasia</td>
                  <td>Contato</td>
                  <td>E-mail</td>
                  <td>Status</td>
                  <td>Status plano</td>
                  <td>Plano contratado</td>
                  <td>Período</td>
                  <td>Vencimento</td>
                  {/* <td>Versão termo de uso</td> */}
                  <td>Editar</td>
                </tr>
              </thead>
              <tbody>
                {loadingData ? (
                  <tr>
                    <td colSpan={9}>
                      <p>Carregando...</p>
                    </td>
                  </tr>
                ) : (
                  <>
                    {items.length > 0 ? (
                      <>
                        {items.map(item => (
                          <tr key={item.id?.toString()}>
                            {/* <td>
                              {
                                item.logo ? (
                                  <a href={`${bucketUrl}/companies/logo/${item.logo}`} target="_blank">
                                    <img src={item.logo ? `${bucketUrl}/companies/logo/${item.logo}` : '/default-user.png'} />
                                  </a>
                                ) : (
                                  <img src={'/default-user.png'} />
                                )
                              }
                            </td> */}

                            <td>{item.fantasy_name}</td>
                            <td>{item.responsible_phone || 'Não informado'}</td>
                            <td>{item.responsible_email || 'Não informado'}</td>
                            <td>{item.blocked ? "Bloqueada" : "Ativa"}</td>
                            <td>{item.signatures.length > 0 ? getPlanStatus(item.signatures[0].status) : "-"}</td>
                            <td>{item.signatures.length > 0 ? item.signatures[0].plan.name : "Não possui"}</td>
                            <td>{item.signatures.length > 0 ? `${item.signatures[0].plan.days} dias` : "-"}</td>
                            <td>
                              {item.signatures.length > 0 ? moment(item.signatures[0].current_period_end).format('DD/MM/YYYY') : "-"}
                            </td>
                            {/* <td>{item?.company_accepted_terms && item?.company_accepted_terms.length > 0 ?
                              item?.company_accepted_terms[0].term_version : "-"}</td> */}
                            <td>
                              <a href={`${process.env.PUBLIC_URL}/empresas/${item.id}`} target="_blank">
                                <button
                                  type="button"
                                // title="Editar"
                                // onClick={() => handleEdit(item)}
                                >
                                  <BiEdit />
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          Nenhum resultado encontrado
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            <Pagination
              pages={totalPages}
              currentPage={currentPage}
              setPage={p => setCurrentPage(p)}
            />
          </div>

        </Table>
      </Container>
    </Main >
  );
};

export default Companies;
