import axios from 'axios';

const bcrypt = require('bcryptjs');

const bucketUrl = `https://storage.googleapis.com/jobplanner-bucket`;

const api = axios.create({
  // baseURL: 'http://localhost:3001/v1/',
  baseURL: 'https://api.jobplanner.com.br/v1/',
});

const hash = bcrypt.hashSync('QrRlu9TVojAgxH7AdmFgqDoQzGXLbKVH', 10);
api.defaults.headers.app_key = hash;

export { bucketUrl };
export default api;
