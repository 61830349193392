import styled, { keyframes } from 'styled-components';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 50px;

  animation: ${appearFromTop} 1s;

  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;

    strong {
      width: 100%;
    }

    &.to-right {
      justify-content: flex-end;
    }

    > div {
      width: 305px;
    }

    form {
      flex: 1;
      display: flex;
      width: calc(100% - 305px);
      flex-wrap: wrap;

      > div {
        min-width: 200px;
        max-width: 28%;
        height: 40px;
        margin-bottom: 0;

        & + div {
          margin-left: 15px;

          @media (max-width: 768px) {
            margin-left: 0;
          }
        }
      }

      .react-select__multi-value__label {
        max-width: 60px;
      }

      .react-select__value-container--is-multi {
        max-height: 38px;
        overflow: auto;
      }
    }

    input.filter-input {
      height: 40px;
      background: #ffffff;
      border: 1px solid #c9c9c9;
      border-radius: 10px;
      padding: 0 10px;
      margin-right: 15px;
      border-radius: 5px;

      &::placeholder {
        color: #c9c9c9;
      }
    }

    button {
      height: 40px;
      background-color: #e75e18;
      border: 1px solid #e75e18;
      color: #fff;
      padding: 0 25px;
      border-radius: 5px;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      border-spacing: 0;
      min-width: 650px;

      thead {
        background-color: #e75e18;
        td {
          padding: 20px 15px;
          text-align: center;
          color: #fff;
          font-weight: 500;
        }
      }

      tbody {
        tr {
          &:nth-child(2n + 1) {
            background-color: #f5f5f5;
          }

          td {
            padding: 10px;
            text-align: center;
            color: #9d9d9d;
            /* max-width: 160px; */
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 20rem;
            /* min-width: 5rem; */

            &.icon {
              svg {
                font-size: 22px;

                &.active {
                  color: #2baf39;
                }

                &.inactive {
                  color: #af081c;
                }
              }
            }

            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
              border-radius: 50%;
              background-color: #9d9d9d;
            }

            button {
              color: #e75e18;
              border: 0;
              font-size: 25px;
              padding: 0;
              height: 20px;
              background-color: transparent;
              margin: 0 15px;

              & + button {
                margin-left: 10px;
              }

              a {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                color: #313131;
                margin-right: 5px;

                svg {
                  margin-right: 3px;
                }
              }
            }

            span.status {
              width: 30px;
              height: 30px;
              background: #ffe35a;
              display: inline-block;
              border-radius: 50%;
            }

            &:last-of-type {
              width: 200px;
            }
          }

          &.loading {
            td {
              padding: 0;
              position: relative;

              .alert {
                border-radius: 0 0 5px 5px;
              }
            }
          }
        }
      }
      .alert {
        background: #ddd;
        padding: 20px;
        border-radius: 10px;
        color: #333;
        font-weight: 500;
      }

      &.dashboard {
        tbody {
          tr {
            td {
              &:first-of-type {
                text-align: left;
                min-width: 100px;
              }

              &:last-of-type {
                width: auto;
              }
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
      border-radius: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ef824b;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #e75e18;
    }
  }

  @media (max-width: 769px) {
    padding: 0;
    background: transparent;

    /* table {
      background: #fff;
      display: flex;

      thead {
        tr {
          width: 100px;
          display: inline-block;
          vertical-align: top;

          td {
            text-align: right;
            display: flex;
            align-items: center;
          }
        }
      }

      tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;

        tr {
          width: 160px;
          display: inline-block;
          vertical-align: top;
          border-right: 1px solid #6788ba;

          td {
            padding: 0 15px;
            color: #9d9d9d;
            display: flex;
            align-items: center;
            height: 61px;
            white-space: normal;
            text-align: left;
          }
        }
      }
    } */
  }
`;
