import React from 'react';
import {
  RouteProps as ReactRouteProps,
  Route as ReactRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  onlyAuthorizedUsers?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isAdmin = false,
  onlyAuthorizedUsers = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate === !!user) {

          // Rotas exclusivas para os administradores
          if (isAdmin && user.role_id === 1) {
            return <Component />;
          }


          // Rotas não protegidas
          if (!isAdmin) {
            return <Component />;
          }

          return (
            <Redirect
              to={{
                pathname: `/`,
                state: { from: location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: isPrivate
                  ? `${process.env.PUBLIC_URL}/login`
                  : `${process.env.PUBLIC_URL}/`,
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default Route;
