import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  fullWidth?: boolean;
}

const InputPassword: React.FC<InputProps> = ({
  name,
  label,
  fullWidth = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { fieldName, defaultValue, error, registerField, clearError } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    clearError();
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleVisible = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Label fullWidth={fullWidth} className="label">
      {!!label && <strong>{label}</strong>}
      <Container isErrored={!!error} isFocused={isFocused} className="input">
        <input
          type={isVisible ? 'text' : 'password'}
          onFocus={() => handleInputFocus()}
          onBlur={() => handleInputBlur()}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
        <button onClick={() => handleVisible()} type="button">{isVisible ? <FiEyeOff /> : <FiEye />}</button>
        {error && (
          <Error title={error}>
            <FiAlertCircle color="c53030" size={20} />
          </Error>
        )}
      </Container>
    </Label>
  );
};

export default InputPassword;
